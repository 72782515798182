import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'

import * as v from '../../config/variables'
import {
  ArrowLongRightIcon,
  ArrowRightIcon,
  MinusIcon,
} from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

export const StyledButton = styled.button`
  outline: none;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  background: transparent;
  border-radius: 0.2rem;
`

export const Button = ({ children }) => <StyledButton>{children}</StyledButton>

const linkStyles = cx(
  'text-sm text-gray-600 hover:text-gray-700 active:text-800',
  'group inline-flex gap-4 items-center rounded-md py-1 px-3 border border-gray-600',
  'hover:border-gray-700 active:border-text-800 transition-all'
)
const iconStyles = 'w-4 h-4' // group-hover:translate-x-0.5 transition-transform

type BigLinkProps = {
  to: string
  children: React.ReactNode
  className?: string
  icon?: any
}

export const BigLink = ({
  children,
  to,
  className,
  icon: Icon,
  ...rest
}: BigLinkProps) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <Link to={to} className={cx(linkStyles, className)} {...rest}>
        <span>{children}</span>
        {Icon ? (
          <Icon className={iconStyles} />
        ) : (
          <ArrowRightIcon className={iconStyles} />
        )}
      </Link>
    )
  }
  return (
    <a className={cx(linkStyles, className)} href={to} {...rest}>
      <span>{children}</span>
      {Icon ? (
        <Icon className={iconStyles} />
      ) : (
        <ArrowRightIcon className={iconStyles} />
      )}
    </a>
  )
}
styled.a`
  display: inline-flex;
  align-items: center;
  /* font-weight: bold; */
  margin: 0.25rem 0;
  color: ${v.grayDarker};
  border-bottom: 2px solid ${v.cyan} !important;
  transform: perspective(1px) translateZ(0);
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
  position: relative;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 3px 8px;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${v.cyan};
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-property: transform;
    transition-duration: 0.2s;
    /* transition-timing-function: ease-out; */
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${v.white} !important;

    &:before {
      transform: scaleY(1);
    }
  }
`
