
import styled from 'styled-components'
// import * as v from '../../config/variables'

export const Section = styled.div`
  padding: 0 0 3rem;
`

export const TextContainer = styled.div`
  max-width: 500px;
  /* margin: 0 auto; */
`
